<template>
    <div>
        <b-card>
            <XcTable
                ref="userTable"
                endpoint="users"
                :columns="tableColumns"
                :buttons="tableButtons"
                :records-per-page="100"
                :search-enabled="true"
            />
        </b-card>

        <modal-confirm-vuexy
            text="Are you sure you want to remove this user? This action is irreversible."
            title="Remove custom field?"
            :ok-function="deleteAccount"
        />
    </div>
</template>

<script>
import XcTable from '@/components/Misc/XcTable.vue'
import XcTableHelper from '@/components/Misc/XcTableHelper'
import State from '@/store/index'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'
import Axios from 'axios'
import {
    BCard,
} from 'bootstrap-vue'

export default {
    name: 'settings.users',
    components: {
        XcTable,
        ModalConfirmVuexy,
        BCard,
    },
    data() {
        return {
            tableButtons: [
                {
                    class: 'btn btn-primary',
                    icon: 'fa-plus',
                    label: 'Add new user',
                    callback: this.addNewUser,
                },
            ],
            tableColumns: [
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'name',
                    title: 'Name',
                    field: 'name',
                    label: 'Name',
                    sortByDefault: XcTableHelper.ORDER_BY_ASC,
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'email',
                    title: 'Email',
                    field: 'email',
                    label: 'Email',
                },
                {
                    type: XcTableHelper.TYPE_BADGE,
                    name: 'status',
                    title: 'Status',
                    field: 'status',
                    label: 'Status',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'created_at',
                    title: 'Created at',
                    field: 'created_at',
                    label: 'Created at',
                },
                {
                    field: 'action',
                    label: 'Action',
                    type: XcTableHelper.TYPE_BUTTONS,
                    disableSort: true,
                    title: '',
                    buttons: [
                        {
                            class: 'mr-50',
                            icon: 'TrashIcon',
                            label: 'Delete',
                            callback: this.deleteUserConfirm,
                        },
                        {
                            class: 'mr-50',
                            icon: 'Edit2Icon',
                            label: 'Edit permissions',
                            callback: this.openEditPermissions,
                        },
                    ],
                },
            ],
            userToDelete: 0,
        }
    },
    methods: {
        addNewUser() {
            this.$router.push({ name: 'settings.users.create' })
        },
        deleteUserConfirm(id) {
            this.userToDelete = id
            this.$bvModal.show('confirmModal')
        },
        deleteAccount() {
            this.deleteModalLoading = true
            new Promise((resolve, reject) => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}users/delete`, data: { id: this.userToDelete }, method: 'DELETE' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }).then(() => {
                this.$refs.userTable.loadData()
                if (this.userToDelete === State.getters.user.id) {
                    this.$store.dispatch('logout')
                    this.$router.push('login')
                }
            })
        },
        openEditPermissions(id) {
            this.$router.push({ name: 'settings.users.permissions', params: { id } })
        },
    },
}
</script>
